import React from 'react'

type DomStatus = 'unknown' | 'loading' | 'ready' | 'error'

export const useScript = (
  src: string,
  options: { removeOnUnmount?: boolean; defer?: boolean; async?: boolean } = {
    async: true,
    removeOnUnmount: true,
  },
) => {
  const [status, setStatus] = React.useState<DomStatus>('loading')
  const optionsRef = React.useRef<{ removeOnUnmount?: boolean }>(options)

  React.useEffect(() => {
    let script = document.querySelector(
      `script[src="${src}"]`,
    ) as HTMLScriptElement

    if (script) {
      const existingStatus = script?.getAttribute('data-status') as DomStatus
      if (existingStatus) {
        setStatus(existingStatus)
        return
      }
    } else {
      script = document.createElement('script')
      script.src = src
      script.async = !!options.async
      script.defer = !!options.defer
      script.setAttribute('data-status', 'loading')

      const setAttributeFromEvent = (event: Event) => {
        const newStatus = event.type === 'load' ? 'ready' : 'error'
        switch (newStatus) {
          case 'ready':
            console.log(`Loaded: ${src}`)
            break
          case 'error':
            console.log(`Error loading: ${src}`)
            break
        }
        script.setAttribute('data-status', newStatus)
        setStatus(newStatus)
      }

      script.addEventListener('load', setAttributeFromEvent)
      script.addEventListener('error', setAttributeFromEvent)

      document.body.appendChild(script)

      const removeOnUnmount = optionsRef.current.removeOnUnmount

      return () => {
        if (removeOnUnmount) {
          script.removeEventListener('load', setAttributeFromEvent)
          script.removeEventListener('error', setAttributeFromEvent)
          script.remove()
        }
      }
    }
  }, [src])

  return status
}
